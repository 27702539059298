const layoutSettings = {
  leftSidebar: {
    theme: "sidebar-gradient-black-blue",
  },
  header: {
    show: true
  },
  searchBox: {
    open: false
  },
  secondarySidebar: { show: false },
  customizer:{
    show:false,
  }
};

export default layoutSettings;
