import DashboardStyles from "app/Components/styles/DashboardStyles";
import React from "react";
import { userHasAccess } from "../libs/helper";
import Dashboard from "app/Components/Dashboard";

let summary = {
  name: "Summary",
  prefix: "/dashboard",
  slug: "summary",
  singularName: "Summary",
  crud: false,
  component: Dashboard,
  updateLabel: "nama",
  filters: [],
  meta: {
    delete_flag: true,
  },
  access: ["superadmin", "provinsi", "kabkota", "dinas", "kecamatan", "opd","operator"],
  delete: true,
  add: true,
  update: true,
  edit: true,
  actions: [],
  
};

export default [
  summary,

];
