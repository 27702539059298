import moment from "moment";

let readMore = {
  name: "Post Statistic",
  slug: "readMore",
  visible: true,
  crud: true,
  singularName: "readMore",
  endpoint: "/post/view-statistics",
  updateLabel: "readMore",
  meta: {
    delete_flag: true,
  },
  access: ["superadmin"],
  delete: false,
  exportToCsv: true,
  noNeedSearch: true,
  noNeedRefresh: true,
  referral: true,
  add: false,
  update: false,
  dataRefFilters: false,
  edit: false,
  actions: [
    
  ],
  fields: [
    {
      label: "ID",
      name: "id",
      type: "auto",
    },{
      label:"Judul Post",
      name:"postTitle",
      type:"text",
      resource_label:(row)=>{
        return row.post.title;
      }
    },{
      label:"Pembuat Post",
      name:"postCreator",
      type:"text",
      resource_label:(row)=>{
        return row.post.name;
      }
    },{
      label:"Symbol",
      name:"symbol",
      type:"text",
      resource_label:(row)=>{
        return row.post.symbol;
      }
    },{
      label:"Username",
      name:"reader",
      type:"text",
      resource_label:(row)=>{
        return row.name;
      }
    },{
      label:"Counter",
      name:"reader",
      type:"text",
      resource_label:(row)=>{
        return row.views;
      }
    },{
      label:"Terakhir Lihat",
      type:"text",
      input: false,
      resource_label:(row)=>{
        return moment(row.created_at).format("YYYY/MM/DD HH:mm");
      }
    }
  ],
};

export default [
  readMore
]