import moment from "moment";

const text = (name, content) => {
  if (typeof content === "undefined") return;
  if (typeof content.payload === "undefined") return;
  if (content.payload === null) return;
  if (typeof content.payload.data === "undefined") return;

  let data = content.payload.data;

  for (let k in data) {
    if (data[k].name.localeCompare(name) === 0) return data[k].txt;
  }
};

const formatDate = (date, isTimeStamp = false, format = "YYYY/MM/DD HH:MM") => {
  const resultDate = isTimeStamp ? moment
  .unix(date)
  .format(format) : moment(date)
  .format(format)

  if(resultDate === "Invalid date" || resultDate.includes("70")) return "-"
  return resultDate
}
const mustHaveValue = (obj) => {
  if(typeof obj === 'undefined') return;
  if(obj === null) return;
  return obj;
}
const getValues = (props, key) => {
  if (typeof props === "undefined") return [];
  if (typeof props.payload === "undefined") return [];
  if (props.payload === null) return [];
  if(typeof props.payload[key] === 'undefined') return [];
  if (props.payload[key] === null) return [];
  return props.payload[key];
};

const getPayload = props => {
  if (typeof props === "undefined") return;
  if (typeof props.payload === "undefined") return;
  if (props.payload === null) return;

  return props.payload;
};

const userHasAccess = (access) => {
  let roles = localStorage.getItem("roles");
  
  roles = roles !== null ? roles.split(",") : ['user','guest'];
  
  if (!roles) return;
  if (roles.length === 0) return;
  for (let k in access) {
    if (roles.includes(access[k])) return true;
  }
 
  return false;
};

const session = ()=>{
  let user_id = localStorage.getItem("user_id");
  let agent_id = localStorage.getItem("agent_id");

  let roles = localStorage.getItem("roles");
  return {
    agent_id,
    user_id,
    roles
  }
  
}
export {
  text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue,
  session,
  formatDate
};

export default {
    text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue
}