import { formatDate } from 'app/libs/helper';
import React from 'react';

let commentSection = {
    name: "Comments",
    slug: "commentsSection",
    visible: true,
    singularName: "CommentSection",
    crud: true,
    endpoint: "/crud/post_comments",
    updateEndpoint: "/v2/comments",
    updateLabel: "CommentSection",
    backTo:'/dashboard/CommentSection',
    meta: {
      delete_flag: true,
    },
    disableMenu: ["referral", "history"],
    menuLabel: {
      profile: "Comment"
    },
    approval: true,
    isApprovable: (row) => {
      return row.status === 1;
    },
    access: ["superadmin"],
    newModal: true,
    delete: true,
    add: true,
    update: true,
    edit: false,
    actions: [
      
    ],
    fields: [
      {
        label:"Created Date",
        type:"text",
        input: false,
        resource_label:(row)=>{
          return formatDate(row.created_at);
        }
      },
      {
        label: "ID",
        name: "id",
        type: "number",
        input: false,
      },
      {
        label: "Isi Komentar",
        name: "txt",
        type: "text",
        visible: true,
      },
      {
        label: "Judul Posting",
        name: "timeline_posts",
        type: "text",
        resource_label:(row)=>{
          if(!row.timeline_posts) return "N/A";
          return row.timeline_posts.title;
        },
        input: false,
      },
      {
        label: "From",
        name: "profiles",
        type: "text",
        resource_label:(row)=>{
          if(!row.user_account) return "N/A";
          if (row.user_account.name === null) return "N/A"
          return `@${row.user_account.username}`;
        },
        visible: true,
        input: false
      },
      {
        label: "Approval Status",
        name: "status",
        resource_label:(row)=>{
          if (row.status === 2) {
            return <div style={{
              color: "#55BBAD",
            }}>Approved</div>
          } 
          if (row.status === 1) {
            return <div style={{
              color: "#FE7D43",
            }}>Pending</div>
          }
          if (row.status === 0) {
            return (
              <div>
                <div style={{
                  color: "#FE4343",
                }}>Rejected</div> 
                <div>{row.reason}</div>
              </div>
            );
          }
          else {
            return "Status: Unknown"
          }
        },
        input: false,
        visible: true
      },
      {
        label: "Mentor",
        name: "mentor",
        type: "text",
        resource_label:(row)=>{
          if(!row.mentor_account) return "N/A";
          if (row.mentor_account.name === null) return "N/A"
          return `@${row.mentor_account.username}`;
        },
        visible: true,
        input: false
      },
      {
        label: "Reason",
        name: "reason",
        visible: false,
        input: false,
      }
    ],
  };

  export default [
    commentSection
  ]