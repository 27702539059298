import React, { Component } from "react";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";

class DropDownMenu extends Component {
  state = {
    open: false
  };
  
  constructor(props) {
    super(props);
    
  }

  componentDidMount(){
    console.log('props',this.props);
  }

  onItemClick = e => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  renderLevels = items =>
    items.map((item, i) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.sub)}
          </DropDownMenuItem>
        );
      } else {
        if(item.name !== 'Daftar Akun Referral' && item.name !== 'Daftar Akun Claim Hist' ) {
          return (
            <li
              key={i}
              className={classList({
                "nav-item": true,
                "selected": this.state.open,
                open: this.state.open
              })}
              onClick={this.props.closeSecSidenav}
            >
              <Link to={item.path}>
                <i className={`nav-icon ${item.icon}`}></i>
                <span className="item-name">{item.name}</span>
              </Link>
            </li>
          );
        }
      }
    });

  render() {
    return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
  }
}

export default DropDownMenu;
