import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({ routeSegments, onEditName, current }) => {
  const refresh = () => window.location.reload(true)

  return (
    <Fragment>
      <div className="breadcrumb">
        {routeSegments ? (
          <Fragment >
            <h1>
              {onEditName ? 
                onEditName : routeSegments[routeSegments.length - 1]["name"]
              }
            </h1>
          </Fragment>
        ) : null}
        <ul>
          {routeSegments
            ? routeSegments.map((route, index) =>
                index !== routeSegments.length - 1 ? (
                  <li key={index}>
                    <NavLink to={route.path}>
                      <span className="capitalize text-muted link-style">
                        {route.name}
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  <li key={index} onClick={refresh}>
                    <span className="capitalize text-muted link-style">{route.name}</span>
                  </li>
                )
              )
            : null}
            {current == "edit" && 
              <li>
                <span className="capitalize text-muted">{onEditName}</span>
              </li>
            }
        </ul>
      </div>
      <div className="separator-breadcrumb border-top"></div>
    </Fragment>
  );
};

export default Breadcrumb;
