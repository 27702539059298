
let mentors = {
  name: "Mentor",
  slug: "mentors",
  singularName: "Mentor",
  crud: true,
  endpoint: "/crud/mentors",
  insertEndpoint: "/admin/mentors",
  updateLabel: "name",
  filters: [
    
  ],
  meta: {
    delete_flag: true,
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Mentor"
  },
  access: ["superadmin","admin",  "provinsi", "kabkota", "dinas", "kecamatan", "opd"],
  delete: true,
  add: true,
  update: true,
  edit: true,
  actions: [
   
  ],
  fields: [
    {
      label: "ID",
      name: "id",
      type: "auto",
    },
    {
      label: "Profile",
      name: "profile_id",
      type: "lookup",
      multiple: false,
      searchable: false,
      store: "profile_id",
      resource: "crud/profiles",
      key: "id",
      valueField: "name",
      value: (data) => {
        return data.nama;
      },
      resource_label: (row) => {
       if (typeof row.profiles === "undefined") return "N/A";
        if (row.profiles === null) return "N/A";
        if (typeof row.profiles.nama === "undefined") return "N/A";
        if (row.profiles.nama === null) return "N/A";
        return row.profiles.nama + ' - ' + row.profiles.email;
        
      },
      defaultValue: (data) => {

        if (typeof data === "undefined") return;
        if (data === null) return;

        try {
          return {
            text: data.profiles.nama + ' - ' + data.profiles.email,
            value: data.profiles.id,
          };
        } catch (error) {
          return null;
        }
      },
    },
    
  ],
};

export default [
  mentors,
];

