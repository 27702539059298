const API_URL = process.env.REACT_APP_API_URI;
const qs = require("qs");
export default class Api {
  async post(url, data, withToken, custom_uri) {

    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          "Content-Type": "application/json",
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        };
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  async put(url, data, withToken, custom_uri) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          "Content-Type": "application/json",
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        };
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  async putMultipart(url, data, withToken, custom_uri) {
    const headers = withToken
    ? {
          'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + localStorage.getItem("token"),
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          'Content-Type': 'multipart/form-data',
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        };
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: headers,
    })
    .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  async get(url, data, withToken, custom_uri) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
          Authorization: "Bearer " + localStorage.getItem("token"),
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          "ngrok-skip-browser-warning": "69420",
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json",
        };
    try{
      let response = await fetch(custom_uri ? custom_uri + url : API_URL + url + "?" + qs.stringify(data), {
        headers: headers,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          return result;
        }).catch(err=>{
          console.log('error',err);
        });
        return response;
    }catch(err){
      console.log('error',err)
      return {
        status:0
      };
    }
  }

  async delete(url, data, withToken) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          "Content-Type": "application/json",
          'ngrok-skip-browser-warning':true,
          'Access-Control-Allow-Origin': '*',
        };

    let response = await fetch(API_URL + url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  login(data) {
    return this.post("/login", data);
  }
  async modules() {
    let response = await fetch(API_URL + "/platform/modules", {})
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }
  register(data) {
    return this.post("/register", data);
  }
  questions(data) {
    return this.get("/questions", data, true);
  }
  result(data) {
    return this.post("/result", data, true);
  }
  async upload(url, data, withToken, custom_uri) {
    const headers = withToken
      ? {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {};

    const formData = new FormData();
    formData.append('file', data[0].file);
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "PUT",
      body: formData,
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }
  /*//this upload will use the xhr so we can track the progress and make it resumable
  async upload2(url, data, withToken, custom_uri, uploadId) {
    
   
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    const statusApi = `/media/status/${uploadId}`;
    let status = await this.get(statusApi, {}, withToken, custom_uri);
    if(status.status === 0){
      throw new Error('unable to connect to server!');
    }
    let startByte = status.startByte ? status.startByte : 0;

    for (let k in data) {
      formData.append(k, data[k]);
    }
    const headers = withToken
      ? {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {};
      
      xhr.open("POST", "upload", true);

      // File id, so that the server knows which file we upload
      xhr.setRequestHeader('X-File-Id', fileId);
      
      // The byte we're resuming from, so the server knows we're resuming
      xhr.setRequestHeader('X-Start-Byte', startByte);
      
      xhr.upload.onprogress = (e) => {
      };
      
      // file can be from input.files[0] or another source
      xhr.send(file.slice(startByte));
     /* 
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "POST",
      body: formData,
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
      

    return response;
  }*/
  async call(opts, withToken) {
    let { endpoint, data, scenario } = opts;
    let body = data;
    let id = data && typeof data.id !== "undefined" ? data.id : null;
    switch (scenario) {
      case "pagination":
        return this.get(endpoint, body, withToken);
      case "read":
        return this.get(endpoint + "/" + id, body, withToken);
      case "insert":
        return this.post(endpoint, body, withToken);
      case "update":
        if (id !== null) delete body.id;
        return this.post(endpoint + "/" + id, body, withToken);
      case "delete":
        return this.delete(endpoint + "/" + id, body, withToken);
      case "post":
        return this.post(endpoint, body, withToken);
      case "put":
        return this.put(endpoint, body, withToken);
      case "put-multipart":
        return this.putMultipart(endpoint, body, withToken);
      case "upload":
        return this.upload(endpoint, body, withToken);
      default:
        return this.get(endpoint, body, withToken);
    }
  }
  crud(opts) {
    const { endpoint, actionType, id, data } = opts;
    switch (actionType) {
      case "ADD":
        return this.post(endpoint, data, true);
      case "UPDATE":
        return this.post(endpoint + "/" + id, data, true);
      case "DELETE":
        return this.delete(endpoint + "/" + id, {}, true);
      case "GET":
        return this.get(endpoint + "/" + id, {}, true);
      case "LOOKUP":
        return this.get(endpoint + "/lookup", data, true);
        
      default:
        return this.get(endpoint, data, true);
    }
  }
  do_action(opts) {
    const { service, actionName, id } = opts;

    return this.get(
      service.endpoint + "/" + actionName.toLowerCase() + "/" + id,
      {},
      true
    );
  }
}
