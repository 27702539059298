let referralSection = {
  name: "Data Referrals",
  slug: "referralsSection",
  visible: true,
  singularName: "ReferralSection",
  crud: true,
  exportToCsv: true,
  endpoint: "/v1/bo/upline",
  updateLabel: "ReferralSection",
  meta: {
    delete_flag: true,
  },
  access: ["superadmin"],
  delete: false,
  noNeedSearch: true,
  noNeedRefresh: true,
  referral: true,
  add: false,
  update: false,
  dataRefFilters: false,
  edit: false,
  actions: [
    
  ],
  fields: [
    {
      label: "Username Upline",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return "@" + row.username;
      }
    },
    {
      label: "Total Jumlah Komisi",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return "Rp " + row.bonus;
      }
    },
  ],
};

export default [
  referralSection
]