import moment from "moment";
import accounting from "accounting";
import { formatDate } from "app/libs/helper";
import referral from "./referral";
let subscriptions = {
  name: "Subscriptions",
  slug: "subscriptions",
  singularName: "Subscriptions",
  crud: true,
  endpoint: "/crud/subscriptions",
  insertEndpoint: "/admin/subscriptions",
  updateEndpoint: "/admin/subscriptions",
  searchReferalEndPoint: "/v1/plans/5?refferal_code=",
  updateLabel: "name",
  referralCodeField: true,
  filters: [
    
  ],
  exportToCsv: true,
  meta: {
    delete_flag: true,
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Subscription"
  },
  access: ["superadmin","admin",  "provinsi", "kabkota", "dinas", "kecamatan", "opd"],
  delete: true,
  excludeAccountId: true,
  add: true,
  update: true,
  edit: true,
  actions: [
   
  ],
  fields: [
    {
      label: "ID",
      name: "id",
      type: "auto",
    },
    {
      label: "Account",
      name: "account_id",
      type: "lookup",
      multiple: false,
      searchable: true,
      store: "profile_id",
      resource: "crud/accounts",
      key: "id",
      valueField: "name",
      value: (data) => {
	        if (!data) return null;
	        return { label: `${data.name} ${data.email} #${data.username}` , value: data.id, email:data.email };
	    },
      resource_label: (row) => {
       if (typeof row.accounts_name === "undefined") return "N/A";
        if (row.accounts_name === null) return "N/A";
       
        return row.accounts_name + ' - ' + row.accounts_email + ' #' + row.accounts_username;
        
      },
      defaultValue: (data) => {
        if (typeof data === "undefined") return;
        if (data === null) return;

        try {
        return { label:  data.accounts_name + ' - ' + data.accounts_email + '-' + data.accounts_username,  
        		value: data.account_id, email: data.email};
          
        } catch (error) {
          return null;
        }
      },
    },
	{
		label:"Plan",
		name:"subs_type",
		type:"lookup",
		values:[1,3,6,12,13],
		defaultValue: (row) => {
            if (!row) return;
            return row.subs_type;
        },
		resource_label:(row)=>{
			return row.subs_type+' Bulan';
		}
	},
	{
		label:"Expiry",
		name:"valid_until",
		type:"text",
		input:(data)=>{
      if(data) return true;
    },
    defaultValue:(row)=>{
      if (typeof row === "undefined") {
        return Date.now();
      }
      if (row === null) {
        return Date.now();
      };
      
      try {
        return moment(row.valid_until*1000);
      } catch (error) {
        return null
      }
    },
		resource_label:(row)=>{
			// return moment(parseInt(row.valid_until)*1000).format("YYYY-MM-DD HH:MM");
      return formatDate(row.valid_until, true)
		}
	},
	{
		label:"Created",
		name:"Created",
		type:"text",
    uneditable: true,
		input:(data)=>{
      if(data) return true;
    },
    defaultValue:(row)=>{
      return "-";
    },
		resource_label:(row)=>{
      // let date = new Date(row.created_at)
      // let dateYear = `${date.getFullYear()}`
      // let dateDay = `${((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))}`
      // let dateMonth = `${(date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}`
      // let dateHour = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}`
      // let dateMin = `${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
      // let formatedDate = `${dateYear}-${dateDay}-${dateMonth} ${dateHour}:${dateMin}`
			
      // return dateYear == '1970' ? "-" : formatedDate;
      return formatDate(row.created_at)
		}
	},
	{
		label:"Updated",
		name:"Updated", 
    uneditable: true,
		type:"text",
		input:(data)=>{
      if(data) return true;
    },
    defaultValue:(row)=>{
      if (typeof row === "undefined") return;
      if (row === null) return;

      try {
          return moment(row.updated_date*1000);
      } catch (error) {
        return null
      }
    },
		resource_label:(row)=>{
      // let date = new Date(row.updated_at)
      // let dateYear = `${date.getFullYear()}`
      // let dateMonth = `${((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))}`
      // let dateDay = `${(date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}`
      // let dateHour = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}`
      // let dateMin = `${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
      // let formatedDate = `${dateYear}-${dateMonth}-${dateDay} ${dateHour}:${dateMin}`
			
      // return dateYear == '1970' ? "-" : formatedDate;
      return formatDate(row.updated_at)
		}
	},
	{
		label:"Last Payment",
		name:"Last Payment",
    uneditable: true,
		type:"text",
		input:(data)=>{
      if(data) return true;
    },
    defaultValue:(row)=>{
      return "-";
    },
		resource_label:(row)=>{
      // let date = new Date(moment
      //   .unix(row.last_payment)
      //   .format())
      // let dateYear = `${date.getFullYear()}`
      // let dateMonth = `${((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))}`
      // let dateDay = `${(date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}`
      // let dateHour = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}`
      // let dateMin = `${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
      // let formatedDate = `${dateYear}-${dateMonth}-${dateDay} ${dateHour}:${dateMin}`

      // return dateYear == '1970' ? "-" : formatedDate;
      return formatDate(row.last_payment, true)
		}
	},
	{
		label:"Status",
		name:"subs_status",
		type:"lookup",
		values:[0,1],
		value:(row)=>{
			return {row};
		},
    defaultValue: (row) => {
        if (!row) return;
        return row.subs_status;
    },
		resource_label:(row)=>{
			let arr = ['inactive','active'];
			return arr[row.subs_status];
		}
	},
  {
    label: "referral_code",
    name: "Referral Code",
    type: "text",
    noShowOnEdit: true,
  },   
  ],
};

let invoices = {
  name: "Invoices",
  slug: "invoices",
  singularName: "Invoices",
  crud: true,
  exportEndpoint: "/export/invoice-csv",
  endpoint: "/crud/invoices",
  insertEndpoint: "/crud/invoices",
  updateLabel: "name",
  filters: [
    
  ],
  exportToCsv: true,
  meta: {
    delete_flag: true,
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Invoice"
  },
  access: ["superadmin","admin",  "provinsi", "kabkota", "dinas", "kecamatan", "opd"],
  delete: true,
  add: true,
  update: true,
  edit: true,
  actions: [
   
  ],
  fields: [
    {
      label: "ID",
      name: "id",
      type: "auto",
    },{
		label:"Order No",
		name:"order_no",
		type:"text",
		resource_label:(row)=>{
			return row.order_no;
		}
	},{
      label: "Account",
      name: "account_id",
      type: "lookup",
      multiple: false,
      searchable: true,
      store: "profile_id",
      resource: "crud/accounts",
      key: "id",
      valueField: "name",
      value: (data) => {
	        if (!data) return null;
	        return { label: `${data.name} ${data.email} #${data.username}` , value: data.id };
	    },
      resource_label: (row) => {
       if (typeof row.accounts_name === "undefined") return "N/A";
        if (row.accounts_name === null) return "N/A";
       
        return row.accounts_name + ' - ' + row.accounts_email + ' #' + row.accounts_username;
        
      },
      defaultValue: (data) => {

        if (typeof data === "undefined") return;
        if (data === null) return;

        try {
        return { label:  data.accounts_name + ' - ' + data.accounts_email + '-' + data.accounts_username,  
        		value: data.account_id, };
          
        } catch (error) {
          return null;
        }
      },
    },{
      label:"Invoice Date",
      type:"text",
      input: false,
      resource_label:(row)=>{
        return moment(row.created_at).format("YYYY/MM/DD HH:mm");
      }
    } ,{
      label:"Fee",
      name:"fee",
      type:"text",
      resource_label:(row)=>{
        return accounting.formatNumber(row.fee,0,'.');
      }
    } ,{
      label:"Amount",
      name:"amount",
      type:"text",
      resource_label:(row)=>{
        return accounting.formatNumber(row.amount,0,'.');
      }
    } ,{
      label:"Status",
      name:"invoice_status",
      type:"lookup",
      values:[0,1],
      value:(row)=>{
        return {row};
      },
      defaultValue: (row) => {
          if (!row) return;
          return row.invoice_status;
      },
      resource_label:(row)=>{
        let arr = ['unpaid','paid','error'];
        return arr[row.invoice_status];
      }
    }   
  ],
};

export default [
  subscriptions,
  invoices,
];