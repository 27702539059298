import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GullLayout from "app/GullLayout/GullLayout";

//Reduxes
import CrudAction from "./redux/actions/CrudAction";
import CrudAddAction from "./redux/actions/CrudAddAction";
import CrudUpdateAction from "./redux/actions/CrudUpdateAction";
import CrudGetAction from "./redux/actions/CrudGetAction";
import CrudDeleteAction from "./redux/actions/CrudDeleteAction";
import DoAction from "./redux/actions/DoAction";
import CallAction from "./redux/actions/CallAction";
import { Actions } from "./redux/reducers/RootReducer";

class withGull extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    let { route } = this.props;
   
    return (
      <Fragment>
        <GullLayout route={route} {...this.props}></GullLayout>
      </Fragment>
    );
  }
}

//withGull.contextType = AppContext;



const mapStateToProps = (state) => {
    const {
      crud,
      crud_add,
      crud_delete,
      crud_update,
      crud_get,
      do_action,
      call_action,
      refresh_account,
    } = state;
    return {
      crud,
      crud_add,
      crud_delete,
      crud_update,
      crud_get,
      do_action,
      call_action,
      refresh_account,
    };
  };
  
const mapDispatchToProps = (dispatch) => ({
    crudAction: (service, data) => dispatch(CrudAction(service, data)),
    crudAddAction: (service, data, translations) =>
      dispatch(CrudAddAction(service, data, translations)),
    crudUpdateAction: (service, id, data, translations) =>
      dispatch(CrudUpdateAction(service, id, data, translations)),
    crudDeleteAction: (service, id, data) =>
      dispatch(CrudDeleteAction(service, id, data)),
    crudGetAction: (service, id, data) =>
      dispatch(CrudGetAction(service, id, data)),
    doAction: (service, actionName, id) =>
      dispatch(DoAction({ service, actionName, id })),
    callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
    refreshAccount: () => dispatch(Actions.refreshAccountAction()),
  });
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withGull));
