import React, { useState } from "react";
import { connect } from "react-redux";
import CallAction from "../redux/actions/CallAction";
import accounting from "accounting";


let options2 = {
  chart: {
    height: 150,
    type: "radialBar",
    toolbar: {
      show: false,
    },
  },
  // plotOptions: {
  //   bar: {
  //     horizontal: true,
  //     dataLabels: {
  //       position: "top",
  //     },
  //   },
  // },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "17px"
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "20px",
          show: true
        }
      }
    }
  },
  dataLabels: {
    enabled: false,
    offsetX: -6,
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  // stroke: {
  //   show: false,
  //   width: 1,
  //   colors: ["#fff"],
  //   lineCap: "round",
  //   curve: "smooth",
  // },
  series: [
    {
      name: "Pagu",
      data: [44, 55, 41, 64, 22, 43, 21],
    },
    {
      name: "Realisasi",
      data: [53, 32, 33, 52, 13, 44, 32],
    },
  ],
  xaxis: {
    categories: [],
    labels: {
      formatter: function (value) {
        return accounting.formatNumber(value, 0, ".");
      },
    },
  },
};


const donut1 = {
  chart: {
    width: 200,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels 
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "17px"
        },
        value: {
          formatter: function(val) {
            return parseFloat(val) + '%';
          },
          color: "#111",
          fontSize: "20px",
          fontWeight: 700,
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  series: [70],
  labels: ["Realisasi"],
};

const donut2 = {
  chart: {
    width: 200,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "17px"
        },
        value: {
          formatter: function(val) {
            return parseFloat(val) + '%';
          },
          color: "#111",
          fontSize: "20px",
          fontWeight: 700,
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#ABE5A1"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  series: [70],
  labels: ["Total"],
  pagu: 0,
  realisasi: 0,
};

const Dashboard = (props) => {
  const { callAction, call_action } = props;
  const [fetching, loading] = useState(false);
  const [akun_options, setAkun] = useState(options2);
  const [satker_options, setSatker] = useState(options2);
  const [program_options, setProgram] = useState(options2);
  const [update, setUpdate] = useState(0);
  const [c, setChart] = useState("akun");
  const [overall, setOverall] = useState(null);
  const [pusat, setPusat] = useState(null);
  const [daerah, setDaerah] = useState([]);
  const [dekon, setDekon] = useState(null);
  const [tp, setTP] = useState(null);
  const [subdit1, setSubdit1] = useState(null);
  const [subdit2, setSubdit2] = useState(null);
  const [subdit3, setSubdit3] = useState(null);
  const [subdit4, setSubdit4] = useState(null);
  const [subdit5, setSubdit5] = useState(null);
  const [subdit6, setSubdit6] = useState(null);

  const [subdit1top, setSubdit1Top] = useState([]);
  const [subdit2top, setSubdit2Top] = useState([]);
  const [subdit3top, setSubdit3Top] = useState([]);
  const [subdit4top, setSubdit4Top] = useState([]);
  const [subdit5top, setSubdit5Top] = useState([]);
  const [subdit6top, setSubdit6Top] = useState([]);
  const [manual, setManualInput] = useState(null);
  const [pusat_top, setPusatTop] = useState([]);
  const [dekon_top, setDekonTop] = useState([]);
  const [tp_top, setTpTop] = useState([]);
  const [eselon2_top, setEselon2Top] = useState([]);
  const [_satker, setSatkerSerie] = useState([]);
 
  return (
    <div>
      <div className={`container-fluid`}>
        
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
