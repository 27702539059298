import styled from "styled-components";

const DashboardStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  action: {
    textAlign: "right",
  },
  topMargin: {
    marginTop: "30px",
  },
  filterButton: {
    marginTop: "20px",
  },
  appbar: {
    backgroundColor: "#fff",
  },
  logo: {
    width: "100px",
  },
  menubar: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  menubarItem: {
    display: "inline-block",
    padding: "10px 30px",
  },
  menuBarItemText: {
    textDecoration: "none",
    color: "#333",
  },
  menubarItemActive: {
    display: "inline-block",
    padding: "10px 30px",

    backgroundColor: "#74b9ff",
  },
  menuBarItemTextActive: {
    textDecoration: "none",
    color: "#fff !important",
    fontWeight: "bold",
  },
  toolbar: {
    minHeight: 10,
  },
  topActionButtons: {
    marginTop: 20,
    marginBottom: 29,
    flex: 1,
    alignSelf: "flex-end",
    textAlign: "right",
  },
  topDesc: {
    flex: 2,
    marginTop: 20,
    marginBottom: 29,
  },
});

export default DashboardStyles;

export const MidColumn = styled.div`
  min-width: 200px;
`;

export const RightColumn = styled.div`
  margin-left: 20px;
  min-width: 600px;

  @media (max-width: 1440px) {
    width: 500px;
    min-width: 500px;
  }

  @media (max-width: 1024px) {
    width: 450px;
    min-width: 450px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
    width: 100%;
    min-width: 100%;
    margin-left: 0px;
    .ul-widget4__item.ul-widget4__users {
      display: flex;
      align-items: flex-start !important;
      overflow-x: auto;
      width: 100%;
      flex-direction: column;
    }
    .ul-widget2__info.ul-widget4__users-info {
      width: 100%;
    }
    .rankno{
      left: 0px !important;
      font-size:16px !important;
      position:absolute;
    }
  }

`;

export const UlWidget = styled.div`
  margin-left: -15px !important;
  width: calc(100% + 30px) !important;

  @media (max-width: 480px) {
    margin-left: 0px !important;
    width: 100% !important;
  }
`;
