import React from "react";
import withAuth from "./withAuth";
import ModuleWrapper from "./ModuleWrapper";
import modules from "./modules";
import withGull from "./withGull";


const LoginContainer = React.lazy(() => import("./Components/LoginContainer"));
const ForgotContainer = React.lazy(() => import("./Components/ForgotContainer"));
const ModuleContainer = React.lazy(() =>
  import("./Components/ModuleContainer")
);

const getModules = (prefix) => {
  let _routes = [];
  for(let i=0; i< modules.length; i++){
    let item = modules[i];
    if (item.divider) continue;
    _routes.push({
      path: (prefix ? `${prefix}/` : "/") + item.slug,
      component: item.crud
        ? withAuth(ModuleWrapper(ModuleContainer, item, modules))
        : withAuth(ModuleWrapper(item.component, item, modules)),
    });
  }
 
  
  return _routes;
};
const getModulesByName = (slug) => {
  for (let k in modules) {
    if (modules[k].slug === slug) return modules[k];
  }
};
const routes = [
  {
    path: "/",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/login",
    component: LoginContainer,
  },
  {
    path: "/forgot",
    component: ForgotContainer,
    
  },
  {
    path: "/dashboard",
    component: withGull,
    routes: getModules('/dashboard'),
  },
  
];

export default routes;
