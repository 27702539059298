let invoiceListSection = {
  name: "Referral Invoices",
  slug: "invoiceListSection",
  visible: true,
  singularName: "invoiceListSection",
  crud: true,
  endpoint: "/v1/bo/oustanding",
  updateLabel: "invoiceListSection",
  meta: {
    delete_flag: true,
  },
  access: ["superadmin"],
  delete: false,
  noNeedSearch: true,
  noNeedRefresh: true,
  add: false,
  update: false,
  dataInvFilters: true,
  uploadReceipt: true,
  edit: false,
  actions: [
    {
       name: "Upload Receipt",
       label: "Comments",
       uri:(rowData)=>{
         return '/dashboard/comments';
       },
       onPush:(rowData)=>{
       
         const {id} = rowData;
         return {data:{id}};
       },
       redirect:true,
   },
  ],
  fields: [
    {
      label: "Username",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return "@" + row.username;
      }
    },
    {
      label: "Status",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return row.payment_info;
      }
    },
    {
      label: "Tahun",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        const month = [
          "Januari","Februari","Maret",
          "April","Mei","Juni","Juli","Agustus",
          "September","Oktober","November","Desember"
        ]
        const dataMonth = new Date(row.payment_date).getMonth()
        const dataYear = new Date(row.payment_date).getFullYear()
        return row.payment_date ? `${month[dataMonth]} ${dataYear}` : "-";
      }
    },
    {
      label: "Nominal",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return "Rp " + row.payment_amount;
      }
    },
    {
      label: "Bank Account",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        if(row.bank_name && row.bank_account && row.bank_holder){
          return `${row.bank_name.toUpperCase()} - ${row.bank_account} ${row.bank_holder}`;
        }else{
          return 'no data bank account'
        }
      }
    },
    {
      label: "Receipt",
      name: "txt",
      type: "text",
      visible: true,
      resource_label:(row)=>{
        return row.receipt;
      }
    },
  ],
};

export default [
  invoiceListSection
]