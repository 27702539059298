import { master, dashboard, content, mentors, subscriptions, commentSection, referralSection, readMore, invoiceListSection } from "./modules/index";

export default [
  {
    name: "div1",
    label: "Dashboard",
    //description: "Summaries",
    divider: true,
    icon: "i-Dashboard",
    access: ["superadmin", "provinsi", "kabkota", "dinas", "kecamatan", "opd","pemda","operator"],
  },
  ...dashboard,
  ...content,
  ...mentors,
  ...subscriptions,
  ...commentSection,
  ...referralSection,
  ...invoiceListSection,
  ...readMore,
  {
    name: "div4",
    label: "Master Data",
  //  description: "lorem ipsum dolor sit amet",
    divider: true,
    icon: "i-Big-Data",
    access: ["superadmin"],
  },
 // account,
 ...master,
];
