//import layout1Settings from "./Layout1/Layout1Settings";
//import layout2Settings from "./Layout2/Layout2Settings";
//import layout3Settings from "./Layout3/Layout3Settings";
import layoutSettings from "./Bigre/LayoutSettings";
export const GullLayoutSettings = {
  activeLayout: "bigre", // layout1, layout2
  dir: "ltr", // ltr, rtl
  //layout1Settings,
  //layout2Settings,
  //layout3Settings,
  layoutSettings,
  customizer: {
    show: false,
    open: false
  },
  footer: {
    show: true
  }
};
