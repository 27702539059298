import account from './account';
import master from './master';
import dashboard from './dashboard';
import content from './content';
import mentors from './mentors';
import subscriptions from './subscriptions';
import commentSection from "./comment";
import referralSection from "./referral";
import readMore from "./readMore";
import invoiceListSection from "./invoiceList";
     
export {
    account,
    master,
    dashboard,
    content,
    mentors,
    subscriptions,
    commentSection,
    referralSection,
    invoiceListSection,
    readMore,
}
export default {
    account,
    master,
    dashboard,
    content,
    mentors,
    subscriptions,
    commentSection,
    referralSection,
    invoiceListSection,
    readMore,
}