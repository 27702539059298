import React from 'react';
import { userHasAccess } from '../libs/helper';
import moment from 'moment';
import accounting from "accounting";

const flags = input => {
  const indikator = [
    {
      text: 'Active',
      value: '0'
    },
    {
      text: 'Inactive',
      value: '1'
    }
  ];

  if (typeof input !== 'undefined') {
    for (let k in indikator) {
      if (indikator[k].value === input) return indikator[k];
    }
  }
  return indikator;
};

let account = {
  name: 'Daftar Akun',
  slug: 'account',
  singularName: 'Akun',
  crud: true,
  endpoint: '/akun',
  updateLabel: 'name',
  filters: [
    {
      name: 'Status',
      values: flags(),
      parameterName: 'account_status',
      access: ['superadmin', 'operator']
    }
  ],
  exportToCsv: true,
  meta: {
    delete_flag: true
  },
  access: ['superadmin'],
  delete: true,
  add: true,
  update: true,
  edit: true,
  actions: [],
  fields: [
    {
      label: 'Nama',
      name: 'name',
      type: 'text'
    },
    {
      label: 'Username',
      name: 'username',
      type: 'text'
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text'
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'text'
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      visible: false
    },
    {
      label: 'Confirm Password',
      name: 'confirm_password',
      type: 'password',
      visible: false
    },
    {
      label: 'Created Date',
      type: 'text',
      input: false,
      resource_label: row => {
        return moment(row.created_at).format("YYYY/MM/DD");
      }
    },
    {
      label: 'Status',
      name: 'account_status',
      type: 'lookup',
      values: [0, 1],
      value: row => {
        return { row };
      },
      defaultValue: row => {
        if (!row) return;
        return row.account_status;
      },
      resource_label: row => {
        let arr = ['active','inactive','banned'];
        return arr[row.account_status];
      }
    }
  ]
};

let accountReferral = {
  name: 'Daftar Akun Referral',
  slug: 'get_referral_hist_by_user',
  singularName: 'Akun',
  crud: false,
  endpoint: '/v1/bo/refferalcode',
  updateLabel: 'name',
  delete: false,
  add: false,
  update: false,
  referral: true,
  edit: false,
  filters: [
    {
      name: 'Status',
      values: flags(),
      parameterName: 'account_status',
      access: ['superadmin', 'operator']
    }
  ],
  exportToCsv: true,
  meta: {
    delete_flag: true
  },
  access: ['superadmin'],
  actions: [],
  fields: [
    {
      label: 'Tanggal Transaksi',
      type: 'text',
      input: false,
      resource_label: row => {
        return moment(row.created_at).format('YYYY-MM-DD HH:MM');
      }
    },
    {
      label: 'Paket Diambil',
      name: 'description',
      type: 'text',
      resource_label: row => {
        return row.description;
      }
    },
    {
      label: 'User Bergabung',
      name: 'username',
      type: 'text',
      resource_label: row => {
        return '@'+row.username;
      }
    },
    {
      label: 'Komisi Didapatkan',
      name: 'bonus',
      type: 'text',
      resource_label:(row)=>{
        return 'Rp'+accounting.formatNumber(row.bonus,0,'.');
      }
    },
  ]
};

let accountClaimHist = {
  name: 'Daftar Akun Claim Hist',
  slug: 'get_claim_hist_by_user',
  singularName: 'Akun',
  crud: false,
  endpoint: '/v1/bo/refferalbonuses',
  updateLabel: 'name',
  delete: false,
  add: false,
  update: false,
  edit: false,
  filters: [
    {
      name: 'Status',
      values: flags(),
      parameterName: 'account_status',
      access: ['superadmin', 'operator']
    }
  ],
  exportToCsv: true,
  meta: {
    delete_flag: true
  },
  access: ['superadmin'],
  actions: [],
  fields: [
    {
      label: 'Invoice',
      name: 'invoice_for',
      type: 'text'
    },
    {
      label: 'Jumlah Klaim',
      name: 'payment_amount',
      type: 'text',
      resource_label:(row)=>{
        return accounting.formatNumber(row.payment_amount,0,'.');
      }
    },
    {
      label: 'Bank Account',
      name: 'bank_name',
      type: 'text',
      resource_label:(row)=>{
        return `${row.bank_name.toUpperCase()} - ${row.bank_account} ${row.bank_holder}`;
      }
    },
    {
      label: 'Tanggal Dibayarkan',
      name: 'payment_date',
      type: 'text',
      resource_label: row => {
        return moment(row.payment_date).format('DD-MM-YYYY');
      }
    }
  ]
};

let pricing = {
  name: 'Pricing',
  slug: 'pricing',
  singularName: 'Akun',
  crud: true,
  endpoint: '/crud/subscription_plans',
  insertEndpoint: '/v1/plans',
  updateLabel: 'name',
  filters: [],
  meta: {
    delete_flag: false
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Pricing"
  },
  access: ['superadmin'],
  delete: false,
  add: true,
  update: true,
  edit: true,
  actions: [],
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'text'
    },
    {
      label: 'Price',
      name: 'price',
      type: 'text'
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text'
    },
    {
      label: 'Subscription Period (month)',
      name: 'subs_length',
      type: 'text',
      resource_label: row => {
        if (!row) return '';
        return row.subs_length + ' month';
      }
    },
    {
      label: 'Show/Hide',
      name: 'is_shown',
      type: 'lookup',
      values: [0, 1],
      resource_label: row => {
        if (row?.is_shown === 1) {
          return 'Show';
        } else {
          return 'Hide';
        }
      }
    }
  ]
};

let setUpReferral = {
  name: 'Set Up Referral',
  slug: 'setUpReferral',
  singularName: 'Referral',
  crud: true,
  formPage: true,
  endpoint: '/v1/bo/refferalsettings',
  insertEndpoint: '/v1/plans',
  updateLabel: 'Referral',
  filters: [],
  meta: {
    delete_flag: false
  },
  access: ['superadmin'],
  delete: false,
  update: true,
  edit: true,
  actions: [],
  fields: [
    {
      label: 'Setting Referral Rate',
      name: 'referral',
      type: 'text',
      desc: 'Komisi untuk upline (pemilik referral link)'
    },
    {
      label: 'Setting Transaction Rate',
      name: 'rate',
      type: 'text',
      desc: 'Untuk user yang baru bergabung menggunakan link referral'
    },
    {
      label: 'Edit',
      type: 'button',
    }
  ]
};

let emiten = {
  name: "Kode Saham",
  slug: "emiten",
  singularName: "Emiten",
  crud: true,
  access: ["superadmin"],
  endpoint: "/crud/emiten",
  backTo:'dashboard/emiten',
  filters: [
    
  ],
  // delete: true,
  newModal: true,
  deleteLabel: 'code',
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Emiten"
  },
  add: true,
  update: true,
  edit: true,
  switch: true,
  fields: [
    {
      label: "Name",
      name: "name",
      type: "text",
    },
    {
      label: "Code",
      name: "code",
      type: "text",
    },
    {
      label: "Discussion status",
      name: "status",
      visible: false,
    }
  ]
}

let sector = {
  name: "Kode Industri",
  slug: "sector",
  singularName: "Sector",
  crud: true,
  access: ["superadmin"],
  endpoint: "/crud/sector_list",
  backTo:'dashboard/sector',
  filters: [
    
  ],
  // delete: true,
  newModal: true,
  deleteLabel: 'name',
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Sector"
  },
  add: true,
  update: true,
  edit: true,
  switch: true,
  fields: [
    {
      label: "Name",
      name: "name",
      type: "text",
    },
    {
      label: "Discussion status",
      name: "status",
      visible: false,
    }
  ]
}

let category = {
  name: "Kategori",
  slug: "category",
  singularName: "Category",
  crud: true,
  access: ["superadmin"],
  endpoint: "/crud/category",
  backTo:'dashboard/category',
  filters: [
    
  ],
  newModal: true,
  deleteLabel: 'name',
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Category"
  },
  // delete: true,
  add: true,
  update: true,
  edit: true,
  fields: [
    {
      label: "Name",
      name: "name",
      type: "text",
    },
  ]
}

export default [account, pricing, setUpReferral,accountReferral,accountClaimHist, emiten, sector, category];
